import React from "react";
import {graphql, HeadProps, PageProps} from "gatsby";

import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import {PageContextType} from "../types/page-context.type";
import TextFormatter from "../utils/TextFormatter";
import Layout from "../components/Layout";
import {SiteMetadata} from "../types/site-metadata.type";
import StructuredData from "../assets/structured-data";
import '../styles/page/terms/terms.style.scss';
import TermItem from "../components/terms/TermItem";

type TermProps = {
  strapiTerm: {
    slug: string,
    siteMetadata: SiteMetadata,
    description: {
      data: {
        description: string,
      },
    },
  }
} & SiteMetadataConfigProps

const Term = ({data, pageContext}: PageProps<TermProps, PageContextType>) => {
  const {description: { data: { description }}, siteMetadata} = data.strapiTerm;
  const formattedDescription = TextFormatter.concatOrphans(description) || description;
  const crumbs = pageContext.breadcrumb.crumbs.filter(v => v.pathname !== '/regulaminy');

  return (
    <Layout crumbs={crumbs} crumbLabel={siteMetadata.crumbLabel}>
      <TermItem content={formattedDescription}/>
    </Layout>
  )
}

export default Term;

export const Head = (props: HeadProps<TermProps>) => {
  const {data: {strapiTerm, site}} = props;
  const slug = props.location.pathname;
  const siteMetadata = strapiTerm.siteMetadata;
  const siteUrl = site.siteMetadata.siteUrl;

  return (
    <>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description}/>
      <meta name="robots" content="noindex, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: siteMetadata.crumbLabel,
          item: siteUrl + slug,
        }
      ])}
    </>
  )
}

export const pageQuery = graphql`
  query TermPage($slug: String) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiTerm(slug: {eq: $slug}) {
      slug
      siteMetadata {
        crumbLabel
        title
        description
      }
      description {
        data {
          description
        }
      }
    }
  }
`;
